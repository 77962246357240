<div class="container" style="background-color: #fff; padding: 100px 0;">
    <div class="row">
        <div class="col-12">
           
  <h1>Sree Magal Travels Privacy Policy</h1>

  <p>This Privacy Policy describes how Sree Magal Travels collects, uses, and discloses your information when you use our drop taxi service.</p>

  <h2>Information We Collect</h2>

  <p>We may collect the following types of information when you use our service:</p>

  <ul>
    <li>Contact Information: Name, phone number, email address, etc.</li>
    <li>Location Information: Pick-up and drop-off locations.</li>
    <li>Payment Information: Credit card details, if applicable.</li>
  </ul>

  <h2>How We Use Your Information</h2>

  <p>We use the collected information for the following purposes:</p>

  <ul>
    <li>Providing and maintaining our drop taxi service.</li>
    <li>Notifying you about changes to our service.</li>
    <li>Improving customer service and support.</li>
  </ul>

  <h2>Disclosure of Your Information</h2>

  <p>We may disclose your information in the following situations:</p>

  <ul>
    <li>To comply with legal obligations.</li>
    <li>To protect and defend our rights and property.</li>
    <li>For the purposes of providing the drop taxi service.</li>
  </ul>

  <h2>Security</h2>

  <p>We take reasonable measures to protect your information from unauthorized access or disclosure.</p>

  <h2>Contact Us</h2>

  <p>If you have any questions about our Privacy Policy, please contact us at <a href="mailto:info@sreemagaltravels.com">info@sreemagaltravels.com</a>.</p>

        </div>
    </div>
</div>