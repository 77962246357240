export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAb3OcOZXeKmEmMw0r0r7Wawe7NVvlBUu4",
    authDomain: "sreemagaltravels.firebaseapp.com",
    databaseURL: "https://sreemagaltravels-default-rtdb.firebaseio.com",
    projectId: "sreemagaltravels",
    storageBucket: "sreemagaltravels.appspot.com",
    messagingSenderId: "430835831800",
    appId: "1:430835831800:web:a30d0b6405b12ff8573c0d"
  }
};
