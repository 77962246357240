import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SITE_SETTINGS } from 'src/app/settings';
import { TaxiService } from 'src/app/taxi.service';

@Component({
  selector: 'app-booking-contact',
  templateUrl: './booking-contact.component.html',
  styleUrls: ['./booking-contact.component.css']
})
export class BookingContactComponent implements OnInit {

  contactFormGroup: FormGroup;
  minDate = new Date();
  tomorrow = new Date();
  submitted = false;
  rideDetails;
  bookingDetails;
  settings = SITE_SETTINGS;
  returnDate;

  constructor(private router: Router, private fb: FormBuilder, private taxiService: TaxiService, private http: HttpClient) { }

  ngOnInit(): void {
    this.taxiService.rideDetails.subscribe(ride => {
      this.rideDetails = ride;
      console.log(this.rideDetails);
    });

   this.returnDate = this.tomorrow.setDate(new Date().getDate() + 1);

    this.contactFormGroup = this.fb.group({
      name: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
      ]],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]],
      pickupDate: [new Date, Validators.required],
      pickupTime: [moment(Date.now()).format('h:mm a'), Validators.required],
      returnDate: [new Date]
    });

    this.bookingDetails = {
      ...this.rideDetails,
    }


  }

  get contactValidation() { return this.contactFormGroup.controls; }


  back() {
    this.router.navigateByUrl('/select-cabs', {skipLocationChange: true});
  }

  booknow() {
    this.submitted = true;
    if (this.contactFormGroup.invalid) {
      return;
    }

    this.bookingDetails = {
      ...this.contactFormGroup.value,
      ...this.bookingDetails,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    }
    
    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'email': this.bookingDetails.email || '',
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate':  moment(this.bookingDetails.pickupDate).format('MM/DD/YYYY') ,
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'roundtrip') ? moment(this.bookingDetails.returnDate).format('MM/DD/YYYY')  : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `${this.settings.adminEmailSubject} ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'appPassword': this.settings.appPassword,
      'appEmail': this.settings.appEmail
    }

    console.log(bookingDetails);

    let returnDate = `${(bookingDetails.tripType === 'One Way') ? '' : 'Return Date:' + bookingDetails.returnDate  }`

    let smsMessage = `
    Thanks for Choosting ${this.settings.siteName}.
    Your Booking Details:\r\n
    Booking ID: ${bookingDetails.bookingId}\r\n
    Name: ${bookingDetails.name}\r\n
    Mobile Number: ${bookingDetails.phoneNo}\r\n
    Pickup Location: ${bookingDetails.origin}\r\n
    Drop Location: ${bookingDetails.destination} \r\n
    Pickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime} \r\n
    ${returnDate} \r\n
    Trip Type: ${bookingDetails.tripType} \r\n
    Total KM: ${bookingDetails.distance} KM\r\n
    Total Trip Fare: ${bookingDetails.estimate} ₹\r\n
    Rate Per KM: ${bookingDetails.ratePerKM} ₹\r\n
    Driver Batta: ${bookingDetails.driverBatta} ₹\r\n
    Toll, parking, permit extra \r\n 
    For any questions please contact ${this.settings.phoneNo} \r\n 
    https://${this.settings.websiteAddress}`




    let whatsappMessage = window.encodeURIComponent(smsMessage);

    let newSMS = `
    Thanks for Choosting ${this.settings.siteName}\r\n
    Booking Id: ${bookingDetails.bookingId}\r\n
    Name: ${bookingDetails.name}\r\n
    Mob. No: ${bookingDetails.phoneNo}\r\n
    Pickup location : ${bookingDetails.origin}\r\n
    Drop location: ${bookingDetails.destination}\r\n
    Date and time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime}\r\n
    For further details: ${this.settings.phoneNo}\r\n
    https://${this.settings.websiteAddress}`


    let smsBody = {
      message: newSMS,
      numbers: `${bookingDetails.phoneNo}`,
      smsKey: this.settings.smsKey
    }

   this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));
    this.http.post(this.settings.smsAPI, smsBody).subscribe(res => console.log(res));

    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");

    this.router.navigateByUrl('/success-booking', { skipLocationChange: true })

    console.log(bookingDetails);


  }
}
