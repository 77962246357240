<section class="banner pt-5 pb-0 overflow-hidden" style="background: linear-gradient( 
    45deg
     , rgba(255,193,7,1) 0%,rgba(255,222,7,1) 100%);">
    <div class="container">
        <div class="banner-in">
            <div class="row align-items-center">
                <div class="col-lg-6 mb-4">
                    <div class="banner-content text-lg-start text-center">
                        <h4 class=" mb-0 text-dark">Sree Magal Travels Service</h4>
                        <h1>Your Outstation Cab Service Partner</h1>
                        <p class="mb-4">For bookings, inquiries, or more information, please feel free to get in touch
                            with our friendly and knowledgeable team. We look forward to serving you and making your
                            outstation travel an unforgettable experience.
                        </p>
                        <a class="btn btn-danger mb-3" href="tel:{{settings.phoneNo}}">
                            <h3 class="text-white p-0 m-0">
                                +91-{{settings.phoneNo}}
                            </h3>
                        </a>

                        <app-outstation></app-outstation>
                    </div>
                </div>
                <div class="col-lg-6 mb-4">
                    <div class="banner-image">
                        <img src="assets/1.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-us pb-6 pt-6" style="background-image:url(assets/images/shape4.png); background-position:center;">
    <div class="container">
        <div class="section-title mb-6 w-50 mx-auto text-center">
            <h4 class="mb-1 theme1">BSree Magal Travels

            </h4>
            <h2 class="mb-1">Book <span class="theme">with Confidence </span></h2>
            <p>We understand the importance of cost-effectiveness. Sree Magal Travels offers competitive pricing
                without compromising on quality. We aim to make your travel not only comfortable but also
                budget-friendly.
            </p>
            <p>
                When you choose Sree Magal Travels for your outstation cab needs, you're not just booking a ride;
                you're becoming a part of a movement that supports women entrepreneurs and safe travel for all. Our
                commitment to excellence, safety, and empowerment sets us apart in the industry. Join us on this
                journey, and experience the difference with Sree Magal Travels.


            </p>
        </div>

        <div class="why-us">
            <div class="why-us-box">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div class="why-us-item text-center p-4 py-5 border rounded bg-white">
                            <div class="why-us-content">
                                <div class="why-us-icon">
                                    <img src="assets/i1.png" alt="" srcset="">
                                </div>
                                <h4><a href="about.html">Safety First</a></h4>
                                <p class="mb-0">At Sree Magal Travels, we prioritize your safety above all else. Our
                                    well-maintained and fully equipped vehicles, driven by experienced and professional
                                    drivers, ensure that you reach your destination without a worry.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div class="why-us-item text-center p-4 py-5 border rounded bg-white">
                            <div class="why-us-content">
                                <div class="why-us-icon">
                                    <img src="assets/best-seller.png" alt="" srcset="">
                                </div>
                                <h4><a href="about.html">Exceptional Service</a></h4>
                                <p class="mb-0">We are dedicated to delivering top-notch customer service. Our team is
                                    committed to making your travel experience comfortable, convenient, and enjoyable.
                                    Whether it's a family vacation, a business trip, or a weekend getaway, we're here to
                                    serve you. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div class="why-us-item text-center p-4 py-5 border rounded bg-white">
                            <div class="why-us-content">
                                <div class="why-us-icon">
                                    <img src="assets/i3.png" alt="" srcset="">
                                </div>
                                <h4><a href="about.html">Empowering Women</a></h4>
                                <p class="mb-0">Sree Magal Travels is proud to be a woman-owned business. We believe in
                                    empowering women and promoting their active participation in the workforce. By
                                    choosing our services, you support a cause that stands for equality and
                                    opportunities for women.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div class="why-us-item text-center p-4 py-5 border rounded bg-white">
                            <div class="why-us-content">
                                <div class="why-us-icon">
                                    <img src="assets/taxi-cab.png" alt="" srcset="">
                                </div>
                                <h4><a href="about.html">Wide Range of Options</a></h4>
                                <p class="mb-0">Our fleet of vehicles caters to a variety of needs. Whether you require
                                    a compact sedan, a spacious SUV, or a large van for group travel, we have the
                                    perfect solution for you. Our versatile options ensure that your journey is tailored
                                    to your requirements.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="white-overlay"></div>
</section>


<section class="trending pb-5 pt-0">
    <div class="container">
        <div class="section-title mb-6 w-50 mx-auto text-center">
            <h4 class="mb-1 theme1">Top Destinations</h4>
            <h2 class="mb-1">Explore <span class="theme">Top Destinations</span></h2>

        </div>
        <div class="row align-items-center">
            <div class="col-lg-5 mb-4">
                <div class="trend-item1">
                    <div class="trend-image position-relative rounded">
                        <img src="assets/chennai.webp" alt="image">
                        <div
                            class="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                            <div class="trend-content-title">
                                <h5 class="mb-0"><a href="tour-grid.html" class="theme1">Tamil Nadu</a></h5>
                                <h3 class="mb-0 white">Chennai</h3>
                            </div>
                            <span class="white bg-theme p-1 px-2 rounded">Book Now</span>
                        </div>
                        <div class="color-overlay"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                        <div class="trend-item1">
                            <div class="trend-image position-relative rounded">
                                <img src="assets/Coimbatore.jpg" alt="image">
                                <div
                                    class="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                                    <div class="trend-content-title">
                                        <h5 class="mb-0"><a href="tour-grid.html" class="theme1">Tamil Nadu</a>
                                        </h5>
                                        <h3 class="mb-0 white">Coimbatore</h3>
                                    </div>
                                    <span class="white bg-theme p-1 px-2 rounded">Book Now</span>
                                </div>
                                <div class="color-overlay"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                        <div class="trend-item1">
                            <div class="trend-image position-relative rounded">
                                <img src="assets/madurai.jpg" alt="image">
                                <div
                                    class="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                                    <div class="trend-content-title">
                                        <h5 class="mb-0"><a href="tour-grid.html" class="theme1">Tamil Nadu</a>
                                        </h5>
                                        <h3 class="mb-0 white">Madurai</h3>
                                    </div>
                                    <span class="white bg-theme p-1 px-2 rounded">Book Now</span>
                                </div>
                                <div class="color-overlay"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                        <div class="trend-item1">
                            <div class="trend-image position-relative rounded">
                                <img src="assets/pondicherry.jpg" alt="image">
                                <div
                                    class="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100">
                                    <div class="trend-content-title">
                                        <h5 class="mb-0"><a href="tour-grid.html" class="theme1">Tamil Nadu</a>
                                        </h5>
                                        <h3 class="mb-0 white">Pondicherry </h3>
                                    </div>
                                    <span class="white bg-theme p-1 px-2 rounded">Book Now</span>
                                </div>
                                <div class="color-overlay"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                        <div class="trend-item1">
                            <div class="trend-image position-relative rounded">
                                <img src="assets/bangalore.jpg" alt="image">
                                <div
                                    class="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                                    <div class="trend-content-title">
                                        <h5 class="mb-0"><a href="tour-grid.html" class="theme1">Karanataka</a>
                                        </h5>
                                        <h3 class="mb-0 white">Bangalore</h3>
                                    </div>
                                    <span class="white bg-theme p-1 px-2 rounded">Book Now</span>
                                </div>
                                <div class="color-overlay"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="about-us pt-0" style="background-image:url(assets/images/bg/bg-trans.png);">
    <div class="container">
        <div class="about-image-box">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-lg-6 mb-4 pe-4">
                    <div class="about-image overflow-hidden">
                        <img src="assets/v2.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6 mb-4 ps-4">
                    <div class="about-content text-center text-lg-start mb-4">
                        <h4 class="theme d-inline-block mb-0">Get To Know Us</h4>
                        <h2 class="border-b mb-2 pb-1">Explore All Tour of the world with us.</h2>
                        <p class="border-b mb-2 pb-2">Welcome to Sree Magal Travels, your trusted partner for
                            hassle-free outstation cab services. Our company is not just about providing transportation;
                            it's about creating memorable journeys and supporting empowerment. Sree Magal Travels is a
                            woman-owned and operated business, proudly paving the way for women entrepreneurs in the
                            transportation industry.


                        </p>
                        <p>
                            Sree Magal Travels was founded with a vision to offer safe and reliable outstation cab
                            services while championing the role of women in the business world. Our journey began with
                            the belief that every individual deserves a comfortable and secure ride to their
                            destination, and we have committed ourselves to fulfilling this promise.


                        </p>
                        <div class="about-listing">
                            <ul class="d-flex justify-content-between">
                                <li><i class="icon-location-pin theme"></i> Quality Drivers</li>
                                <li><i class="icon-briefcase theme"></i> Friendly Price</li>
                                <li><i class="icon-folder theme"></i> Reliable Tour Package</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="white-overlay"></div>
</section>


<section class="trending pb-0">
    <div class="container">
        <div class="row align-items-center justify-content-between mb-6 ">
            <div class="col-lg-7">
                <div class="section-title text-center text-lg-start">
                    <h4 class="mb-1 theme1">Top Pick</h4>
                    <h2 class="mb-1">Best <span class="theme">Tour Packages</span></h2>
                    <p>{{settings.siteName}} presents each intercity (outstation) taxi booking
                        services as well as intercity (nearby) cab services and coimbatore airport taxi.
                    </p>
                </div>
            </div>
            <div class="col-lg-5">
            </div>
        </div>
        <div class="trend-box">
            <div class="row item-slider">
                <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                    <div class="trend-item rounded box-shadow">
                        <div class="trend-image position-relative">
                            <img src="assets/tiruv.jpg" alt="image" class="">
                        </div>
                        <div class="trend-content p-4 pt-5 position-relative">

                            <h5 class="theme mb-1"><i class="flaticon-location-pin"></i> Tamil Nadu</h5>
                            <h3 class="mb-1"><a href="tour-grid.html">Ooty Tour Packages starting @ Rs 1500
                                </a></h3>


                            <div class="entry-meta">
                                <p>
                                    Ooty trip packages are perfect for your trips since it embraces everything from food
                                    and sightseeing to some of the most exquisite hotels in the hill station.
                                </p>
                                <h5>Package Including</h5>
                                <ul>
                                    <li>Flight / Train / Bus - Tickets</li>
                                    <li>Hotels</li>
                                    <li>Taxi Booking</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                    <div class="trend-item rounded box-shadow">
                        <div class="trend-image position-relative">
                            <img src="assets/goa.webp" alt="image" class="">
                        </div>
                        <div class="trend-content p-4 pt-5 position-relative">
                            <h5 class="theme mb-1"><i class="flaticon-location-pin"></i> GOA</h5>

                            <h3 class="mb-1"><a href="tour-grid.html">Goa Tour Packages starting @ Rs 2500

                                </a></h3>


                            <div class="entry-meta">
                                <div class="entry-author d-flex align-items-center">
                                    <p>
                                        Perfect Goa tour packages for you. Find a host of tour packages for Goa with us
                                        and visit Panjim, Anjuna, with best prices
                                    </p>
                                </div>


                                <h5>Package Including</h5>
                                <ul>
                                    <li>Flight / Train / Bus - Tickets</li>
                                    <li>Hotels</li>
                                    <li>Taxi Booking</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                    <div class="trend-item rounded box-shadow">
                        <div class="trend-image position-relative">
                            <img src="assets/Tirupati.jpeg" alt="image" class="">
                        </div>
                        <div class="trend-content p-4 pt-5 position-relative">
                            <h5 class="theme mb-1"><i class="flaticon-location-pin"></i> Tirupati</h5>

                            <h3 class="mb-1"><a href="tour-grid.html">Tirupati tour package starting @ Rs 1250

                                </a></h3>


                            <div class="entry-meta">
                                <p>
                                    Book your Tirupati Tour Packages at low cost price with Bestbus.in. We are offering
                                    Special & VIP Balaji Darshan Tickets, Srikalahasti, Kanipakam tours.
                                </p>
                                <h5>Package Including</h5>
                                <ul>
                                    <li>Flight / Train / Bus - Tickets</li>
                                    <li>Hotels</li>
                                    <li>Taxi Booking</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<section *ngIf="places" class="trending pb-0 pt-6" style="background-image: url(assets/images/shape2.png);">
    <div class="container">
        <div class="section-title mb-6 w-75 mx-auto text-center">
            <h2 class="mb-1"><span>Top Drop Taxi Routes</span></h2>
            <p> Tamil Nadu Top drop taxi routes.
            </p>
        </div>
        <div class="trend-box">
            <div class="row">
                <div class="col-lg-12 mb-4">
                    <div *ngFor="let city of places" class="trend-item1 rounded box-shadow mb-4">
                        <div routerLink="/city/{{city.cityName}}" class="trend-image position-relative">
                            <img src="{{city.image}}" alt="image" class="">
                            <div class="trend-content1 p-4">
                                <h3 class="mb-1 white"><a href="tour-grid.html" class="white">{{city.cityName}} Drop
                                        Taxi</a>
                                </h3>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<section class="discount-action pt-6"
    style="background-image:url(assets/images/section-bg1.png); background-position:center;">
    <div class="section-shape section-shape1 top-inherit bottom-0"
        style="background-image: url(assets/images/shape8.png);">
    </div>
    <div class="container">
        <div class="call-banner rounded pt-10 pb-14">
            <div class="call-banner-inner w-75 mx-auto text-center px-5">
                <div class="trend-content-main">
                    <div class="trend-content mb-5 pb-2 px-5">
                        <h5 class="mb-1 theme">Love Where Your're Going</h5>
                        <h2><a href="detail-fullwidth.html">Explore Your Life, <span class="theme1"> Travel Where
                                    You Want!</span></a></h2>
                        <p>All type of cars / Maxi cabs available. Experienced drivers. 20 years of experience. 24x7 cab
                            service we provide all over Tamil Nadu and Bangalore.</p>
                        <a class="btn btn-default" href="tel:{{settings.phoneNo}}"
                            style="background-color: rgb(255 193 7);">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="white-overlay"></div>
</section>