<div class="wrapper" id="booking">
    <mat-horizontal-stepper #stepper>
        <mat-step [stepControl]="firstFormGroup" [editable]="editable">



            <div class="form_wrapper">
                <div class="form_container">
                    <div class="title_container">
                        <h2>Airport Pick Up & Drop Taxi</h2>
                    </div>
                    <div class="row clearfix">
                        <div class="">
                            <form [formGroup]="firstFormGroup">
                                <div>
                                    <label>Choose Location</label>
                                    <div class="input_field">
                                        <span>
                                            <mat-icon>location_on</mat-icon>
                                        </span>
                                        <mat-select formControlName="origin">
                                            <mat-option *ngFor="let city of rentalCity" [value]="city.value">
                                                {{city.value}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="submitted && contactValidation.name.errors">
                                            Select pickup <strong>location</strong>
                                        </mat-error>
                                    </div>
                                </div>



                                <div class="row clearfix">
                                    <div class="col_half">
                                        <div>
                                            <label>Full Name</label>
                                            <div class="input_field"> <span>
                                                    <mat-icon>person</mat-icon>
                                                </span>
                                                <input type="text" formControlName="name" placeholder="Full Name" />
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col_half">
                                        <div>
                                            <label>Mobile Number</label>

                                            <div class="input_field"> <span>
                                                    <mat-icon>phone_in_talk</mat-icon>
                                                </span>
                                                <input type="number" formControlName="phone" placeholder="Mobile Number"
                                                    required />
                                            </div>
                                        </div>
                                    </div>
                                    <mat-error *ngIf="submitted && contactValidation.name.errors">
                                        Name is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="submitted && contactValidation.phone?.errors?.required">
                                        Mobile number is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="submitted && contactValidation.phone?.errors?.pattern">
                                        Enter valid <strong> Mobile number </strong>
                                    </mat-error>
                                </div>
                                <div>
                                    <label>Journey Type</label>
                                    <div class="input_field"> <span>
                                            <mat-icon>email</mat-icon>
                                        </span>
                                        <mat-select (selectionChange)="packageChange($event.value)"
                                            formControlName="airportTripType">
                                            <mat-option *ngFor="let pkg of airportTripType" [value]="pkg.value">
                                                {{pkg.viewValue}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="submitted && contactValidation.rental?.errors?.required">
                                            Select valid <strong>package</strong>
                                        </mat-error>
                                    </div>
                                </div>
                                <!-- <div>
                                    <label>Email(optional)</label>
                                    <div class="input_field"> <span>
                                            <mat-icon>email</mat-icon>
                                        </span>
                                        <input type="email" formControlName="email" placeholder="Email" />
                                        <mat-error *ngIf="submitted && contactValidation.email?.errors?.pattern">
                                            Enter valid <strong>Email ID</strong>
                                        </mat-error>
                                    </div>
                                </div> -->
                                <div class="row clearfix">
                                    <div class="col_half">
                                        <div>
                                            <label>Pickup Date</label>
                                            <div class="input_field"> <span>
                                                    <mat-icon>event</mat-icon>
                                                </span>
                                                <input type="text" formControlName="pickupDate"
                                                    placeholder="Pickup date" formControlName="pickupDate"
                                                    [min]="minDate" (click)="picker.open()" [matDatepicker]="picker"
                                                    readonly>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col_half">
                                        <div>
                                            <label>
                                                Pickup Time
                                            </label>
                                            <div class="input_field"> <span>
                                                    <mat-icon>
                                                        schedule
                                                    </mat-icon>
                                                </span>
                                                <input type="text" placeholder="Pickup time"
                                                    formControlName="pickupTime" [ngxTimepicker]="time" />
                                                <ngx-material-timepicker #time></ngx-material-timepicker>
                                                <mat-error
                                                    *ngIf="submitted && contactValidation.pickupTime?.errors?.required">
                                                    Pickup Time is <strong>required</strong>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                    <mat-error *ngIf="submitted && contactValidation.pickupDate?.errors?.required">
                                        Pickup Date is <strong>required</strong>
                                    </mat-error>
                                </div>

                                <div class="carbox">
                                    <div *ngFor="let car of cars" class="col">


                                        <div class="cars-item" (click)="selectedCarType(car)">



                                            <label>
                                                ₹ {{car.tripEstimation}}
                                            </label>
                                            <img *ngIf="car.carType !== selectedCarName" src="{{car.image}}">
                                            <img *ngIf="car.carType === selectedCarName" src="{{car.activeImage}}">
                                            <label>{{car.carType}}</label>
                                        </div>


                                    </div>
                                </div>


                                <input class="button" type="submit" value="Get Estimation" (click)="requestAride()" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </mat-step>

        <mat-step>

            <div *ngIf="show && rideDetails" class="estimate-wrap">
               
                <strong style="
                display: block;
                font-size: 38px;
                color: #fff;
                margin: 20px 0;
            ">
                    <span>Fare</span> {{rideDetails.tripEstimation | currency:'INR' }}
                </strong>
                <b>Total Distance: </b>{{rideDetails.distance}} KM <br>
                <b>Selected Car:</b> {{rideDetails.carType}} <br>
                <br>

                <em style="
                margin: 20px 0;
                display: inline-block;
            ">Note: Above estimation is exclusive of Toll Gate and State Permit Etc</em>
                <div class="col-12 text-center mt-3 mb-3">
                    <button (click)="confirm()" mat-raised-button color="primary">Confirm Booking</button>
                </div>
            </div>

        </mat-step>
        <mat-step>
            <div class="success">
                <div style="text-align: center;" *ngIf="bookingDetails">
                    <h4>Ride Booked Successfully</h4>
                    <img src="https://images.ctfassets.net/509kpi6dw56l/4zLD6wiohRdGrrQtWGD0KO/49149fa4ce096618802c2c2b0fee57a1/success.gif?h=250"
                        alt="" srcset="">
                    <p>
                        Thanks for Choosing {{settings.siteName}}, reservation details have been sent
                        to your email id & phone.
                    </p>
                    <div style="text-align: center;">
                        <button (click)="home()" mat-raised-button color="primary">Home</button>
                    </div>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>